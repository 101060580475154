/* eslint-disable react/jsx-wrap-multilines */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Fade,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Component } from 'react';
import { OPS_LOGS_DEPARTMENT_ENUM, OPS_LOGS_OPERATION_CATEGORY_ENUM } from '../../utils/constants';

/**
 * AdminReportFilter - Parameter filter modal
 *
 * @description This component is a pop up modal that includes various fields to filter the
 * type of report on admin report page. Filters include date, time, type of report, and various other controls.
 * @param {String} serialNumber Robot serial number
 * @param {Boolean} showSettingModal Modal controller
 * @param {Function} setShowSettingModal Setter for showing or hiding modals
 * @param {Date} startTime Report start time
 * @param {Function} setStartTime Setter for report start time
 * @param {Date} endTime Report end time
 * @param {Function} setEndTime Setter for report end time
 * @param {Number} aggregateOverUnit Unit to use for aggregation, day, week, hour, etc
 * @param {Function} setAggregateOverUnit Setter
 * @param {Number} aggregateOver Value to aggregate over
 * @param {Function} setAggregateOver Setter
 * @param {String} selectedDiagnosticsType Type of report selected
 * @param {String} motorReportType Motor report type determines type of data shown on chart. Eg, Average charge over time
 * @param {Function} setMotorReportType Setter
 * @param {String} hydraulicReportType Determines type of data shown on chart.
 * @param {Function} setHydraulicReportType Setter
 * @param {String} batteryReportType Determines type of data shown on chart like total battery charge over time
 * @param {Function} setBatteryReportType Setter
 * @param {String} eStopReportType Determines type of data shown on chart like number of estops over time
 * @param {Function} setEStopReportType Setter.
 * @param {Function} setUpdateChartsOptions Setter. Modifying this param will trigger a re-render to refresh the chart
 * @param {Boolean} isOpsLogsReportSelected True when Operation logs report type is selected
 * @param {String} operationLogsDepartmnet Department responsible for fixing operation downtime issue.
 * @param {Function} setOperationLogsDepartment Setter
 * @param {String} operationLogsCategory Category of ops downtime. Examples include electrical, mechanical, etc
 * @param {Function} setOperationLogsCategory Setter
 * @param {Function} fetchOpsLogsData Function responsible for fetching operations logs data
 * @param {Object} Backdrop Styling controls for backdrop components
 * @param {Object} DateFnsUtils Date library
 * @param {Object} classes CSS classes
 * @returns {Component} React functional component
 */
const AdminReportFilter = ({
  serialNumber,
  showSettingModal,
  setShowSettingModal,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  aggregateOverUnit,
  setAggregateOverUnit,
  aggregateOver,
  setAggregateOver,
  selectedDiagnosticsType,
  motorReportType,
  setMotorReportType,
  hydraulicReportType,
  setHydraulicReportType,
  batteryReportType,
  setBatteryReportType,
  eStopReportType,
  setEStopReportType,
  setUpdateChartsOptions,
  isOpsLogsReportSelected,
  operationLogsDepartmnet,
  setOperationLogsDepartment,
  operationLogsCategory,
  setOperationLogsCategory,
  fetchOpsLogsData,
  Backdrop,
  classes
}) => (
  <Modal
    open={showSettingModal}
    onClose={() => setShowSettingModal(false)}
    className={classes.modal}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}
  >
    <Fade in={showSettingModal}>
      <Box className={classes.paper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box>
            <KeyboardDateTimePicker
              margin="normal"
              id="date-picker-dialog"
              label="Start Time"
              value={startTime}
              onChange={(date) => setStartTime(new Date(date).getTime())}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </Box>
          <Box>
            <KeyboardDateTimePicker
              margin="normal"
              id="date-picker-dialog"
              label="end time"
              value={endTime}
              onChange={(date) => setEndTime(new Date(date).getTime())}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </Box>
          {!isOpsLogsReportSelected && (
            <Box>
              <Box>
                <InputLabel id="aggregate-over-unit">Resolution Unit</InputLabel>
                <Select
                  labelId="aggregate-over-unit"
                  id="aggregate-over-unit"
                  value={aggregateOverUnit}
                  onChange={(event) => setAggregateOverUnit(event.target.value)}
                >
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="day">Day</MenuItem>
                  <MenuItem value="hour">Hour</MenuItem>
                  <MenuItem value="minute">Minute</MenuItem>
                  <MenuItem value="second">Second</MenuItem>
                  <MenuItem value="decisecond">Deci-second</MenuItem>
                </Select>
              </Box>
              <Box>
                <TextField
                  id="standard-basic"
                  label="resolution per unit"
                  type="number"
                  onChange={({ target: { value } }) => {
                    if (!Number.isNaN(value) && value > 0) setAggregateOver(value);
                  }}
                  value={aggregateOver}
                />
              </Box>
              <Box>
                <InputLabel id="report-type">Report Type</InputLabel>
                {selectedDiagnosticsType === 'Motors' ? (
                  <Select
                    labelId="motor-type"
                    id="motor-type"
                    value={motorReportType}
                    onChange={(event) => setMotorReportType(event.target.value)}
                  >
                    <MenuItem value="UP_TIME">UpTime</MenuItem>
                    <MenuItem value="RPM">RPM</MenuItem>
                    <MenuItem value="CURRENT">Current</MenuItem>
                    <MenuItem value="MOTOR_TEMP">Motor Temperature</MenuItem>
                    <MenuItem value="MC_TEMP">Motor Controller Temperature</MenuItem>
                  </Select>
                ) : null}
                {selectedDiagnosticsType === 'Hydraulics' ? (
                  <Select
                    labelId="hydraulic-type"
                    id="hydraulic-type"
                    value={hydraulicReportType}
                    onChange={(event) => setHydraulicReportType(event.target.value)}
                  >
                    <MenuItem value="OIL_TEMP">Oil Temperature</MenuItem>
                    <MenuItem value="HYD_PRESSURE">Hydraulic Pressure</MenuItem>
                    <MenuItem value="LIQUID_LEVELS" disabled>
                      Liquid Levels
                    </MenuItem>
                  </Select>
                ) : null}
                {selectedDiagnosticsType === 'Batteries' ? (
                  <Select
                    labelId="battery-type"
                    id="battery-type"
                    value={batteryReportType}
                    onChange={(event) => setBatteryReportType(event.target.value)}
                  >
                    <MenuItem value="AVERAGE">Average</MenuItem>
                  </Select>
                ) : null}
                {selectedDiagnosticsType === 'Safety' ? (
                  <Select
                    labelId="estop-type"
                    id="estop-type"
                    value={eStopReportType}
                    onChange={(event) => setEStopReportType(event.target.value)}
                  >
                    <MenuItem value="SAFETY">safety</MenuItem>
                  </Select>
                ) : null}
              </Box>
            </Box>
          )}
          <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
            <TextField
              labelId="category"
              id="category"
              label="Category"
              select
              value={operationLogsCategory}
              onChange={(event) => setOperationLogsCategory(event.target.value)}
            >
              {OPS_LOGS_OPERATION_CATEGORY_ENUM.map((dept) => (
                <MenuItem key={dept.value} value={dept.value}>
                  {dept.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              labelId="department"
              id="department"
              label="Department"
              select
              value={operationLogsDepartmnet}
              onChange={(event) => setOperationLogsDepartment(event.target.value)}
            >
              {OPS_LOGS_DEPARTMENT_ENUM.map((dept) => (
                <MenuItem key={dept.value} value={dept.value}>
                  {dept.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={classes.modalCloseButtonContainer}>
            <Button
              disabled={!serialNumber}
              backgroundColor={serialNumber ? 'red' : 'black'}
              onClick={() => {
                if (isOpsLogsReportSelected) {
                  setShowSettingModal(false);
                  setUpdateChartsOptions(true);
                } else {
                  setShowSettingModal(false);
                  setUpdateChartsOptions(true);
                }
              }}
            >
              Generate Report
            </Button>
          </Box>
        </MuiPickersUtilsProvider>
      </Box>
    </Fade>
  </Modal>
);

export default AdminReportFilter;
