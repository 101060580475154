import { Button, Typography } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { useObserver } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useStores } from '../../store/root/root.store';

export const ControlImageCapture = ({ service, disabled, showOpsFormDialog }) => {
  const { controlStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  return useObserver(() => (
    <Button endIcon={<CreateIcon disabled={disabled} style={{ fontSize: '25px' }} />} onClick={showOpsFormDialog}>
      <Typography>Record Downtime</Typography>
    </Button>
  ));
};
