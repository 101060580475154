/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';
import OperationLineChart from '../../components/AdminDashboard/LineChart';
import { guardedClient } from '../../utils/axios-instance';
import ReloadIcon from '../../components/reload/reload-icon';
import { ROUTE_DIAGNOSTICS } from '../routes';
import RobotsTable from '../../components/tables/RobotsTable';
import { addIntervalToDate } from '../../utils/time.utils';
import { autoWidth, constructExcel, mergeEqualAdjacentCellsInColumn, saveExcelWorkook } from '../../utils/excel.utils';
import { formatDateTime } from '../../utils/ui.utils';
import ExportIcon from '../../components/export/export-icon';
import { ADMIN_REPORT_TYPES, DEFAULT_REPORT_TYPES, OPS_LOGS_DEPARTMENT_ENUM } from '../../utils/constants';
import OperationLogsTable from '../../components/tables/OperationLogTable';
import { getOpeartionsLogsReports } from '../../services/api/admin-reports-service';
import AdminReportFilter from '../../components/inputs/AdminReportFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  text: {
    color: theme.palette.inverted.main
  },
  button: {
    margin: theme.spacing(1)
  },
  settingButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiIconButton-label': {
      '& svg': {
        fontSize: '3.5rem',

        color: theme.palette.inverted.main
      }
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.inverted.main,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& > div': { marginBottom: '5px' },
    maxWidth: 320
  },
  modalCloseButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
    '& > button': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.inverted.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  pieChartContainer: {
    margin: '50px 10px',
    padding: '5px 10px 15px 10px',
    color: '#FFF',
    border: '1px solid #fff',
    backgroundColor: 'rgba(255,255,255,.1)',
    borderRadius: '8px'
  },
  pieChartHeader: { color: '#fff', textAlign: 'center' },
  toggleGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(0.5)
  },
  titleLable: {
    color: '#fff',
    marginTop: theme.spacing(1)
  },
  toggleButtonGroup: {
    backgroundColor: theme.palette.grey[200]
  },
  safetyChart: {
    marginBottom: theme.spacing(2)
  },
  divider: {
    backgroundColor: 'rgba(256, 256, 256, 0.8)',
    margin: theme.spacing(1),
    width: '98%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}));

const getHistoryChartDataArrays = (initialForm) => {
  if (!initialForm?.length) return null;
  const finalForm = Object.keys(initialForm[0]).reduce((acc, key) => ({ ...acc, [key]: [] }), {});
  initialForm.forEach((element) => {
    Object.keys(finalForm).forEach((key) => finalForm[key].push(element[key]));
  });
  return finalForm;
};

const shiftDate = (list, aggregateOver, aggregateOverUnit) => {
  if (list.length === 0) return list;
  const firstItem = Object.keys(list[0]).reduce(
    (acc, curr) => (curr === 'interval_start' ? { ...acc, [curr]: list[0][curr] } : { ...acc, [curr]: 0 }),
    {}
  );
  const newList = [firstItem];
  const lastInterval = addIntervalToDate(new Date(list[list.length - 1].interval_start), aggregateOver, aggregateOverUnit);
  for (let i = 0; i <= list.length - 1; i++) {
    newList.push({ ...list[i], interval_start: list[i + 1]?.interval_start });
  }
  if (lastInterval.getTime() > new Date(list[list.length - 1].interval_start.replace('+00:00', '')).getTime()) {
    newList.pop();
  } else {
    newList[newList.length - 1].interval_start = lastInterval.toISOString().replace('Z', '+00:00');
  }
  return newList;
};

export const AdminReportsPage = observer(() => {
  const [motorDiagnosticsHistoryData, setMotorDiagnosticsHistoryData] = useState();
  const [hydraulicDiagnosticsHistoryData, setHydraulicDiagnosticsHistoryData] = useState();
  const [batteryDiagnosticsHistoryData, setBatteryDiagnosticsHistoryData] = useState();
  const [estopDiagnosticsHistoryData, setEstopDiagnosticsHistoryData] = useState();
  const [motorDiagnosticsHistoryRawData, setMotorDiagnosticsHistoryRawData] = useState();
  const [hydraulicDiagnosticsHistoryRawData, setHydraulicDiagnosticsHistoryRawData] = useState();
  const [batteryDiagnosticsHistoryRawData, setBatteryDiagnosticsHistoryRawData] = useState();
  const [estopDiagnosticsHistoryRawData, setEstopDiagnosticsHistoryRawData] = useState();
  const [operationLogsData, setOperationLogsData] = useState([]);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [updateChartsOptions, setUpdateChartsOptions] = useState(false);
  const [startTime, setStartTime] = useState(new Date().setHours(0, 0, 0, 0));
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [aggregateOver, setAggregateOver] = useState(1);
  const [aggregateOverUnit, setAggregateOverUnit] = useState('hour');
  const { autonomyRobotStore } = useStores();
  const [selectedDiagnosticsType, setSelectedDiagnosticsType] = useState(null);
  const [motorReportType, setMotorReportType] = useState(DEFAULT_REPORT_TYPES.DEAULT_MOTOR_REPORT_TYPE);
  const [hydraulicReportType, setHydraulicReportType] = useState(DEFAULT_REPORT_TYPES.DEAULT_HYDRAULIC_REPORT_TYPE);
  const [eStopReportType, setEStopReportType] = useState(DEFAULT_REPORT_TYPES.DEAULT_ESTOP_REPORT_TYPE);
  const [batteryReportType, setBatteryReportType] = useState(DEFAULT_REPORT_TYPES.DEFAULT_BATTERY_REPORT_TYPE);
  const [showOperationLogsTable, setShowOperationLogsTable] = useState(false);
  const [isOpsLogsReportSelected, setIsOpsLogsReportsSelected] = useState(false);
  const [operationLogsDepartmnet, setOperationLogsDepartment] = useState(undefined);
  const [operationLogsCategory, setOperationLogsCategory] = useState(undefined);
  const [isLoading, setLoadingList] = useState({});
  const robot = autonomyRobotStore.getSelectedRobot();
  const serialNumber = autonomyRobotStore.getSelectedRobotSerialNumber();

  const classes = useStyles();

  // Sets state of loadingItem in isLoading
  const setLoading = (name, state) => {
    const timestamp = new Date();
    isLoading[name] = { state, timestamp };
    setLoadingList({ ...isLoading });
  };

  useEffect(() => {
    autonomyRobotStore.getRobots();
  }, []);

  useEffect(() => {
    if (serialNumber) {
      setUpdateChartsOptions(true);
    }
    setShowOperationLogsTable(false);
  }, [serialNumber, selectedDiagnosticsType]);

  const motorsUpTimeResultsPostProcess = (results) => {
    const modefiedResults = [];
    modefiedResults.push(results[0]);
    for (let i = 1; i < results.length; i++) {
      modefiedResults.push({
        interval_start: results[i].interval_start,
        left_motor_operation_hours:
          Number(modefiedResults[i - 1].left_motor_operation_hours) + Number(results[i].left_motor_operation_hours),
        right_motor_operation_hours:
          Number(modefiedResults[i - 1].right_motor_operation_hours) + Number(results[i].right_motor_operation_hours),
        hydraulic_motor_operation_hours:
          Number(modefiedResults[i - 1].hydraulic_motor_operation_hours) + Number(results[i].hydraulic_motor_operation_hours)
      });
    }
    return modefiedResults;
  };

  const liquidLevelResultsPostProcess = (results) => {
    const modefiedResults = [];
    modefiedResults.push(results[0]);
    for (let i = 1; i < results.length; i++) {
      modefiedResults.push({
        interval_start: results[i].interval_start,
        base_lls_data: Number(modefiedResults[i - 1].base_lls_data) + Number(results[i].base_lls_data),
        tank_lls_data: Number(modefiedResults[i - 1].tank_lls_data) + Number(results[i].tank_lls_data)
      });
    }
    return modefiedResults;
  };

  const safetyResultsPostProcess = (results) => {
    const modefiedResults = [];
    modefiedResults.push(results[0]);
    for (let i = 1; i < results.length; i++) {
      modefiedResults.push({
        interval_start: results[i].interval_start,
        estops_data: Number(modefiedResults[i - 1].estops_data) + Number(results[i].estops_data),
        sw_estops_data: Number(modefiedResults[i - 1].sw_estops_data) + Number(results[i].sw_estops_data),
        sensing_edges_data: Number(modefiedResults[i - 1].sensing_edges_data) + Number(results[i].sensing_edges_data)
      });
    }
    return modefiedResults;
  };

  const getBatteriesDiagnosticsData = async () => {
    setLoading('Batteries', true);
    guardedClient
      .get('/diagnostics/batteries-diagnostics', {
        params: { startTime, endTime, aggregateOver, aggregateOverUnit, serialNumber, batteryReportType }
      })
      .then(({ data: { results } }) => {
        const shiftedResults = shiftDate(results, aggregateOver, aggregateOverUnit);
        setBatteryDiagnosticsHistoryRawData(shiftedResults);
        setBatteryDiagnosticsHistoryData(getHistoryChartDataArrays(shiftedResults));
        setLoading('Batteries', false);
      });
  };

  const getMotorsDiagnosticsData = async () => {
    setLoading('Motors', true);
    guardedClient
      .get('/diagnostics/motors-diagnostics', {
        params: { startTime, endTime, aggregateOver, aggregateOverUnit, serialNumber, motorReportType }
      })
      .then(({ data: { results } }) => {
        const shiftedResults = shiftDate(results, aggregateOver, aggregateOverUnit);
        setMotorDiagnosticsHistoryRawData(shiftedResults);
        if (motorReportType === 'UP_TIME') {
          const modifiedResults = motorsUpTimeResultsPostProcess(shiftedResults);
          setMotorDiagnosticsHistoryData(getHistoryChartDataArrays(modifiedResults));
        } else {
          setMotorDiagnosticsHistoryData(getHistoryChartDataArrays(shiftedResults));
        }
        setLoading('Motors', false);
      });
  };

  const getHydraulicsDiagnosticsData = async () => {
    setLoading('Hydraulics', true);
    guardedClient
      .get('/diagnostics/hydraulics-diagnostics', {
        params: { startTime, endTime, aggregateOver, aggregateOverUnit, serialNumber, hydraulicReportType }
      })
      .then(({ data: { results } }) => {
        const shiftedResults = shiftDate(results, aggregateOver, aggregateOverUnit);
        setHydraulicDiagnosticsHistoryRawData(shiftedResults);
        if (hydraulicReportType === 'LIQUID_LEVELS') {
          const modifiedResults = liquidLevelResultsPostProcess(shiftedResults);
          setHydraulicDiagnosticsHistoryData(getHistoryChartDataArrays(modifiedResults));
        } else {
          setHydraulicDiagnosticsHistoryData(getHistoryChartDataArrays(shiftedResults));
        }
        setLoading('Hydraulics', false);
      })
      .catch((err) => {
        console.error(`failed to get hydraulics diagnostics data. error: ${err}`);
        setLoading('Hydraulics', false);
      });
  };

  const getEStopsDiagnosticsData = async () => {
    setLoading('Safety', true);
    guardedClient
      .get('/diagnostics/eStops-diagnostics', {
        params: { startTime, endTime, aggregateOver, aggregateOverUnit, serialNumber, eStopReportType }
      })
      .then(({ data: { results } }) => {
        const shiftedResults = shiftDate(results, aggregateOver, aggregateOverUnit);
        setEstopDiagnosticsHistoryRawData(shiftedResults);
        const modifiedResults = safetyResultsPostProcess(shiftedResults);
        setEstopDiagnosticsHistoryData(getHistoryChartDataArrays(modifiedResults));
        setLoading('Safety', false);
      });
  };

  /**
   * @description Handler for fetching Ops logs data
   * @returns None
   */
  const fetchOpsLogsData = async () => {
    if (showOperationLogsTable) setShowOperationLogsTable(false);
    const downtimeStart = new Date(startTime).toISOString();
    const downtimeEnd = new Date(endTime).toISOString();
    const result = await getOpeartionsLogsReports(serialNumber, downtimeStart, downtimeEnd, operationLogsCategory);
    if (result?.result) {
      setOperationLogsData(result.result);
      setIsOpsLogsReportsSelected(true);
      setShowOperationLogsTable(true);
    }
  };

  const getDiagnosticsData = async () => {
    if (isOpsLogsReportSelected) {
      await fetchOpsLogsData();
      return;
    }
    switch (selectedDiagnosticsType) {
      case 'Batteries':
        getBatteriesDiagnosticsData();
        break;
      case 'Motors':
        getMotorsDiagnosticsData();
        break;
      case 'Hydraulics':
        getHydraulicsDiagnosticsData();
        break;
      case 'Safety':
        getEStopsDiagnosticsData();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (updateChartsOptions) {
      getDiagnosticsData();
      setUpdateChartsOptions(false);
    }
  }, [updateChartsOptions]);

  const { interval_start: motorChartLabel, ...motorChartFullData } = motorDiagnosticsHistoryData || {};
  const { interval_start: hydraulicChartLabel, ...hydraulicChartFullData } = hydraulicDiagnosticsHistoryData || {};

  const { interval_start: batteryChartLabel, ...batteryChartFullData } = batteryDiagnosticsHistoryData || {};
  const { interval_start: estopChartLabel, ...estopChartFullData } = estopDiagnosticsHistoryData || {};

  const chartDataMap = {
    Motors: {
      UP_TIME: {
        Left_Motor_Operation_Hours: motorChartFullData.left_motor_operation_hours,
        Right_Motor_Operation_Hours: motorChartFullData.right_motor_operation_hours,
        Hydraulic_Motor_Operation_Hours: motorChartFullData.hydraulic_motor_operation_hours
      },
      RPM: {
        Left_Motor_RPM: motorChartFullData.left_motor_rpm,
        Right_Motor_RPM: motorChartFullData.right_motor_rpm,
        Hydraulic_Motor_RPM: motorChartFullData.hydraulic_motor_rpm
      },
      CURRENT: {
        Left_Motor_Current: motorChartFullData.left_motor_current,
        Right_Motor_Current: motorChartFullData.right_motor_current,
        Hydraulic_Motor_Current: motorChartFullData.hydraulic_motor_current
      },
      MOTOR_TEMP: {
        Left_Motor_Temp: motorChartFullData.left_motor_temp,
        Right_Motor_Temp: motorChartFullData.right_motor_temp,
        Hydraulic_Motor_Temp: motorChartFullData.hydraulic_motor_temp
      },
      MC_TEMP: {
        Left_Motor_MC_Temp: motorChartFullData.left_motor_mc_temp,
        Right_Motor_MC_Temp: motorChartFullData.right_motor_mc_temp,
        Hydraulic_Motor_MC_Temp: motorChartFullData.hydraulic_motor_mc_temp
      }
    },
    Hydraulics: {
      OIL_TEMP: {
        Hydraulic_Oil_Temperature: hydraulicChartFullData.hydraulic_oil_temp
      },
      HYD_PRESSURE: {
        Hydraulic_Oil_Pressure: hydraulicChartFullData.hydraulic_oil_pressure
      },
      LIQUID_LEVELS: {
        Base_Liquid_Level: hydraulicChartFullData.base_lls_data,
        Tank_Liquid_Level: hydraulicChartFullData.tank_lls_data
      }
    },
    Batteries: {
      AVERAGE: {
        Avg_Battery_Percentage: batteryChartFullData.avg_battery_percentage
      }
    },
    Safety: {
      SAFETY: {
        eStops: estopChartFullData.estops_data,
        SW_eStops: estopChartFullData.sw_estops_data,
        Sensing_Edges: estopChartFullData.sensing_edges_data
      }
    }
  };

  const yAxisTitleMap = {
    Motors: {
      UP_TIME: 'UpTime (Hours)',
      RPM: 'RPM (Rounds Per Minute)',
      CURRENT: 'Current (Amps)',
      MOTOR_TEMP: 'Motor Temp (Celsius)',
      MC_TEMP: 'MC Temp (Celsius)'
    },
    Hydraulics: {
      OIL_TEMP: 'Temperature (Celsius)',
      HYD_PRESSURE: 'PSI (Pound-force per square inch)',
      LIQUID_LEVELS: 'Number of Leak Events'
    },
    Batteries: {
      AVERAGE: 'Percentage'
    },
    Safety: {
      SAFETY: 'Number of Events'
    }
  };

  const getChartData = () => {
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.MOTORS) {
      return chartDataMap[selectedDiagnosticsType][motorReportType];
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.HYDRAULICS) {
      return chartDataMap[selectedDiagnosticsType][hydraulicReportType];
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.BATTERIES) {
      return chartDataMap[selectedDiagnosticsType][batteryReportType];
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.SAFETY) {
      return chartDataMap[selectedDiagnosticsType][eStopReportType];
    }
    return {};
  };

  const getYAxisTitle = () => {
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.MOTORS) {
      return yAxisTitleMap[selectedDiagnosticsType][motorReportType];
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.HYDRAULICS) {
      return yAxisTitleMap[selectedDiagnosticsType][hydraulicReportType];
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.BATTERIES) {
      return yAxisTitleMap[selectedDiagnosticsType][batteryReportType];
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.SAFETY) {
      return yAxisTitleMap[selectedDiagnosticsType][eStopReportType];
    }
    return {};
  };

  const chartLabel = {
    Motors: motorChartLabel,
    Hydraulics: hydraulicChartLabel,
    Batteries: batteryChartLabel,
    Safety: estopChartLabel
  };

  const chartExtraData = {
    Batteries: batteryChartFullData.num_of_batteries
  };

  const chartTooltip = {
    Batteries: (label, value, formattedValue, context) =>
      `${label}: ${formattedValue}% (${context.dataset.extraData[context.dataIndex] || 0} Batteries)`
  };

  const chartMainMaxValues = {
    Batteries: {
      min: 0,
      max: 100
    }
  };

  const sheetData = {
    Motors: motorDiagnosticsHistoryRawData,
    Batteries: batteryDiagnosticsHistoryRawData,
    Safety: estopDiagnosticsHistoryRawData,
    Hydraulics: hydraulicDiagnosticsHistoryRawData
  };

  const sheetNames = {
    Motors: 'Motors Report',
    Batteries: 'Batteries Report',
    Safety: 'Safety Report',
    Hydraulics: 'Hydraulics Report'
  };
  const sheetColumns = {
    Motors: {
      UP_TIME: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Left Motor Operation Hours', key: 'left_motor_operation_hours' },
        { header: 'Right Motor Operation Hours', key: 'right_motor_operation_hours' },
        { header: 'Hydraulic Motor Operation Hours', key: 'hydraulic_motor_operation_hours' }
      ],
      RPM: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Left Motor RPM', key: 'left_motor_rpm' },
        { header: 'Right Motor RPM', key: 'right_motor_rpm' },
        { header: 'Hydraulic Motor RPM', key: 'hydraulic_motor_rpm' }
      ],
      CURRENT: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Left Motor Current', key: 'left_motor_current' },
        { header: 'Right Motor Current', key: 'right_motor_current' },
        { header: 'Hydraulic Motor Current', key: 'hydraulic_motor_current' }
      ],
      MOTOR_TEMP: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Left Motor Temp', key: 'left_motor_temp' },
        { header: 'Right Motor Temp', key: 'right_motor_temp' },
        { header: 'Hydraulic Motor Temp', key: 'hydraulic_motor_temp' }
      ],
      MC_TEMP: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Left Motor MC Temp', key: 'left_motor_mc_temp' },
        { header: 'Right Motor MC Temp', key: 'right_motor_mc_temp' },
        { header: 'Hydraulic Motor MC Temp', key: 'hydraulic_motor_mc_temp' }
      ]
    },
    Hydraulics: {
      OIL_TEMP: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Hydraulic Oil Temperature', key: 'hydraulic_oil_temp' }
      ],
      HYD_PRESSURE: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Hydraulic Oil Pressure', key: 'hydraulic_oil_pressure' }
      ],
      LIQUID_LEVELS: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Base Liquid Level', key: 'base_lls_data' },
        { header: 'Tank Liquid Level', key: 'tank_lls_data' }
      ]
    },
    Batteries: {
      AVERAGE: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Average Battery Percentage', key: 'avg_battery_percentage' },
        { header: 'Number of Batteries', key: 'num_of_batteries' }
      ]
    },
    Safety: {
      SAFETY: [
        { header: 'Time', key: 'interval_start' },
        { header: 'Number of Estops', key: 'estops_data' },
        { header: 'Number of SW Estops', key: 'sw_estops_data' },
        { header: 'Number of Sensing Edges', key: 'sensing_edges_data' }
      ]
    },
    getCurrentSheetColumns() {
      if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.MOTORS) {
        return sheetColumns.Motors[motorReportType];
      }
      if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.HYDRAULICS) {
        return sheetColumns.Hydraulics[hydraulicReportType];
      }
      if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.BATTERIES) {
        return sheetColumns.Batteries[batteryReportType];
      }
      if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.SAFETY) {
        return sheetColumns.Safety[eStopReportType];
      }
      return [];
    }
  };

  const isSelectedDiagnosticsTypeValidHasData = () => {
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.MOTORS) {
      return motorDiagnosticsHistoryData;
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.HYDRAULICS) {
      return hydraulicDiagnosticsHistoryData;
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.BATTERIES) {
      return batteryDiagnosticsHistoryData;
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.SAFETY) {
      return estopDiagnosticsHistoryData;
    }
    if (selectedDiagnosticsType === ADMIN_REPORT_TYPES.OPERATIONS) {
      return true;
    }
    return false;
  };

  const handleDiagnosticsHistoryExport = async () => {
    const sheetName = sheetNames[selectedDiagnosticsType];
    const sheetDataContent = sheetData[selectedDiagnosticsType].map((dataPoint) => ({
      ...dataPoint,
      interval_start: formatDateTime(dataPoint.interval_start)
    }));
    const workbook = constructExcel(sheetDataContent, sheetColumns.getCurrentSheetColumns(), sheetName);
    const worksheet = workbook.worksheets[0];
    autoWidth(worksheet);
    mergeEqualAdjacentCellsInColumn(worksheet, 1, 1);
    await saveExcelWorkook(workbook, sheetName);
  };

  return (
    <Fade in>
      <Grid container alignItems="stretch" direction="row" justifyContent="center" spacing={2}>
        {/* {Left panel side, robot selection table} */}
        <Grid item xs={12} md={4}>
          <Typography align="left" variant="h3" className={classes.text}>
            {robot?.name ? `Selected robot: ${robot.name}` : 'Select a robot...'}
          </Typography>
          <RobotsTable selectable location={ROUTE_DIAGNOSTICS} propertyDropDown realTimeUpdate />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container justifyContent="center" direction="row">
            <Button
              size="large"
              className={classes.button}
              style={{ backgroundColor: selectedDiagnosticsType === 'Batteries' ? '#EA2840' : '' }}
              onClick={() => {
                setSelectedDiagnosticsType('Batteries');
              }}
              variant="contained"
            >
              Batteries Report
            </Button>
            <Button
              size="large"
              className={classes.button}
              style={{ backgroundColor: selectedDiagnosticsType === 'Motors' ? '#EA2840' : '' }}
              onClick={() => {
                setSelectedDiagnosticsType('Motors');
              }}
              variant="contained"
            >
              Motors Report
            </Button>
            <Button
              size="large"
              className={classes.button}
              style={{ backgroundColor: selectedDiagnosticsType === 'Hydraulics' ? '#EA2840' : '' }}
              onClick={() => {
                setSelectedDiagnosticsType('Hydraulics');
              }}
              variant="contained"
            >
              Hydraulics Report
            </Button>
            <Button
              size="large"
              className={classes.button}
              style={{ backgroundColor: selectedDiagnosticsType === 'Safety' ? '#EA2840' : '' }}
              onClick={() => {
                setSelectedDiagnosticsType('Safety');
              }}
              variant="contained"
            >
              Safety Report
            </Button>
            <Button
              size="large"
              className={classes.button}
              style={{ backgroundColor: selectedDiagnosticsType === ADMIN_REPORT_TYPES.OPERATIONS ? '#EA2840' : '' }}
              onClick={() => {
                setSelectedDiagnosticsType(ADMIN_REPORT_TYPES.OPERATIONS);
                fetchOpsLogsData();
              }}
              variant="contained"
              disabled={!serialNumber}
            >
              Operation Logs
            </Button>
          </Grid>
          {selectedDiagnosticsType && (
            <Grid container direction="row">
              <Grid item xs={8}>
                <Typography variant="h2" className={classes.titleLable}>
                  {`${selectedDiagnosticsType} Report`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.settingButtonContainer}>
                  <ExportIcon
                    title="Export Report"
                    isLoading={isLoading.Export ? isLoading.Export.state : false}
                    handler={async () => {
                      setLoading('Export', true);
                      await handleDiagnosticsHistoryExport();
                      setLoading('Export', false);
                    }}
                    style={{ color: 'white' }}
                  />
                  <ReloadIcon
                    title="Reload"
                    handler={() => setUpdateChartsOptions(true)}
                    isLoading={isLoading[selectedDiagnosticsType] ? isLoading[selectedDiagnosticsType].state : false}
                    style={{ color: 'white' }}
                  />
                  <IconButton onClick={() => setShowSettingModal(true)}>
                    <SettingsIcon />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.safetyChart}>
                {!isSelectedDiagnosticsTypeValidHasData() ? (
                  <h1 style={{ color: '#fff' }}>
                    {`There is no enough data in selected time range between ${format(new Date(startTime), "yyyy-MM-dd' - 'HH:mm")}
                and ${format(new Date(endTime), "yyyy-MM-dd' - 'HH:mm")}. Please change settings`}
                  </h1>
                ) : null}
                {isSelectedDiagnosticsTypeValidHasData() && !showOperationLogsTable && selectedDiagnosticsType !== 'Operations' ? (
                  <div>
                    {!updateChartsOptions && (aggregateOverUnit === 'second' || aggregateOverUnit === 'decisecond') ? (
                      <h1 style={{ color: '#fff' }}>
                        {`Cannot display data with resolution of ${aggregateOver} ${aggregateOverUnit}. Only Export is available for this resolution.`}
                      </h1>
                    ) : (
                      !updateChartsOptions &&
                      !showOperationLogsTable &&
                      selectedDiagnosticsType !== 'Operations' && (
                        <OperationLineChart
                          chartLabel={chartLabel[selectedDiagnosticsType]}
                          chartData={getChartData()}
                          chartExtraData={chartExtraData[selectedDiagnosticsType]}
                          tooltipLabelCallback={chartTooltip[selectedDiagnosticsType]}
                          yAxisMinMax={chartMainMaxValues[selectedDiagnosticsType]}
                          yAxisTitle={getYAxisTitle()}
                          safety
                        />
                      )
                    )}
                  </div>
                ) : selectedDiagnosticsType === 'Operations' && showOperationLogsTable ? (
                  <OperationLogsTable data={operationLogsData} />
                ) : null}
              </Grid>
              {selectedDiagnosticsType !== 'Operations' && (
                <Grid item xs={12}>
                  <Divider style={{ display: `${showOperationLogsTable && 'hidden'}` }} variant="middle" className={classes.divider} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        {showSettingModal && (
          <AdminReportFilter
            serialNumber={serialNumber}
            showSettingModal={showSettingModal}
            setShowSettingModal={setShowSettingModal}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            aggregateOverUnit={aggregateOverUnit}
            setAggregateOverUnit={setAggregateOverUnit}
            aggregateOve={aggregateOver}
            setAggregateOver={setAggregateOver}
            selectedDiagnosticsType={selectedDiagnosticsType}
            motorReportType={motorReportType}
            setMotorReportType={setMotorReportType}
            hydraulicReportType={hydraulicReportType}
            setHydraulicReportType={setHydraulicReportType}
            batteryReportType={batteryReportType}
            setBatteryReportType={setBatteryReportType}
            eStopReportType={eStopReportType}
            setEStopReportType={setEStopReportType}
            setUpdateChartsOptions={setUpdateChartsOptions}
            isOpsLogsReportSelected={isOpsLogsReportSelected}
            operationLogsDepartmnet={operationLogsDepartmnet}
            setOperationLogsDepartment={setOperationLogsDepartment}
            operationLogsCategory={operationLogsCategory}
            setOperationLogsCategory={setOperationLogsCategory}
            fetchOpsLogsData={fetchOpsLogsData}
            Backdrop={Backdrop}
            classes={classes}
          />
        )}
      </Grid>
    </Fade>
  );
});
