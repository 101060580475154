import { guardedClient } from '../../utils/axios-instance';

/**
 * This function makes a GET request to retrieve ops logs report data within
 * a given set of filter parameters
 * @param {String} serialNumber Robot serial number
 * @param {Boolean} isAutonomous True if robot is in autonomous mode
 * @param {Datetime} downtimeStart Start time
 * @param {Datetime} downtimeEnd End time
 * @param {Enumerator} category Category for downtime can be electrical, mechanical, etc
 * @param {String} subCategory More detailed cause for downtime like GPS, video streaming issue, etc
 * @param {Enumerator} department The department equiped to fix issue
 * @returns
 */
export const getOpeartionsLogsReports = async (serialNumber, downtimeStart, downtimeEnd, category, subCategory, department) => {
  const apiPramaters = {
    serialNumber,
    downtimeStart,
    downtimeEnd,
    category,
    subCategory,
    department
  };
  try {
    const result = await guardedClient.get('/diagnostics/ops-log', {
      params: apiPramaters
    });
    return result.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * This functuion makes a POST request to register operations log on the db
 * @param {String} serialNumber Robot serial number
 * @param {Datetime} downtimeStart Start of event
 * @param {Enumerator} category Root cause of issue. Category for downtime can be electrical, mechanical, etc
 * @param {String} operator Name of operator at time of event
 * @param {Datetime} downtimeEnd End of event
 * @param {String} subCategory More detailed explanation for downtime like 'calibrating gps'
 * @param {String} additionalNotes Optional extra notes from user
 * @param {Enumerator} department Department responsible for addressing issue
 * @param {Object} robotState Robot state snapshot at time of event
 * @returns
 */
export const postOperatiosLogsReport = async (
  serialNumber,
  downtimeStart,
  category,
  operator,
  subCategory,
  additionalNotes,
  department,
  robotState
) => {
  try {
    const result = await guardedClient.post('/diagnostics/ops-log', {
      serialNumber,
      downtimeStart,
      category,
      operator,
      subCategory,
      additionalNotes,
      department,
      robotState
    });
    return result;
  } catch (e) {
    console.error(e);
    return null;
  }
};
