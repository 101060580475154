import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  TextField
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { OPS_LOGS_DEPARTMENT_ENUM, OPS_LOGS_OPERATION_CATEGORY_ENUM, OPS_LOGS_SUB_CATEGORIES } from '../../utils/constants';
import { useStores } from '../../store/root/root.store';
import { postOperatiosLogsReport } from '../../services/api/admin-reports-service';
import ProgressModal from '../dialogs/ProgressModal';

// Custom hook for styling components using Material-UI's theme.
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: 'transparent',
    margin: '0 auto',
    padding: '2px',
    position: 'absolute'
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2)
  }
}));

/**
 * OperationLogsForm - Ops Logs Input Form Component
 *
 * This component renders a dialog form for creating operation logs.
 * It leverages the MobX observer to react to store changes and uses
 * Material-UI components for the UI.
 *
 * @param {Object} props - Component properties.
 * @param {Boolean} props.showDialogue - Controls the visibility of the dialog.
 * @param {Function} props.onClose - Callback to handle dialog closure.
 * @param {String} props.serialNumber - The robot's serial number.
 * @param {Object} props.containerRef - A reference to the container element.
 * @returns {JSX.Element} The rendered OperationLogsForm component.
 */
const OperationLogsForm = observer(({ showDialogue, onClose, serialNumber, containerRef }) => {
  const classes = useStyles();
  // Retrieve stores from the context.
  const { MultiRobotsStore, applicationStore } = useStores();
  const robotStateSnap = MultiRobotsStore.robots.get(serialNumber)?.robotStateSnapShot;

  // useState declarations for form values and modal visibility (omitted documentation as per instructions)
  const [startTime, setStartTime] = useState(new Date());
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [department, setDepartment] = useState('');
  const operator = localStorage.getItem('username');
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [apiCallStatus, setApiCallStatus] = useState('');
  const [apiCallMessage, setApiCallMessage] = useState('');
  const [progressModalTitle, setProgressModalTitle] = useState('');

  /**
   * handleSubmitForm - Asynchronously submits the operation log form.
   *
   * This function performs the following actions:
   * - Sets the progress modal to show a loading state.
   * - Validates that required fields (serialNumber, startTime, category, department) are provided.
   * - Calls the API to post the operations log report.
   * - Handles success by updating UI state and closing the dialog after a timeout.
   * - Handles errors by showing an error message and closing the dialog after a timeout.
   *
   * @async
   */
  const handleSubmitForm = async () => {
    try {
      setProgressModalTitle('Creating Log');
      setApiCallStatus('loading');
      setShowProgressModal(true);
      if (serialNumber && startTime && category && department) {
        const formattedStartTime = startTime.toISOString();
        const result = await postOperatiosLogsReport(
          serialNumber,
          formattedStartTime,
          category,
          operator,
          subCategory,
          additionalNotes,
          department,
          robotStateSnap
        );
        if (result.status === 200) {
          setApiCallStatus('success');
          setProgressModalTitle('Log Created');
          setTimeout(() => {
            setShowProgressModal(false);
            setApiCallStatus('');
            setApiCallMessage('');
            onClose();
          }, 1500);
        }
      } else {
        applicationStore.pushError('Error', 'Start Time, Category, and Department cannot be empty. Please fill out the form and try again');
      }
    } catch (e) {
      console.error(e);
      setApiCallStatus('error');
      setProgressModalTitle('');
      setApiCallMessage('Error! Please Contact the Autonomy Team');
      setTimeout(() => {
        setApiCallMessage('');
        setProgressModalTitle('');
        setShowProgressModal(false);
        onClose();
      }, 2500);
    }
  };

  // TODO
  const handleSetDatetime = (e) => {
    const dateInput = e.target.value;
    const dateTimeObject = new Date(dateInput);
    dateTimeObject.setSeconds(dateTimeObject.getSeconds(), 0);
    setStartTime(dateTimeObject);
  };

  return (
    <Dialog
      open={showDialogue}
      fullWidth
      hideBackdrop={false}
      onClose={onClose}
      className={classes.mainContainer}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent'
        }
      }}
      container={containerRef?.current}
    >
      <ProgressModal open={showProgressModal} status={apiCallStatus} message={apiCallMessage} title={progressModalTitle} />
      <DialogTitle style={{ textAlign: 'center' }}>Operations Log</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Operator Name (full width) */}
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField required id="operator" label="Operator Name" variant="outlined" value={operator} disabled={!!operator} />
            </FormControl>
          </Grid>
          {/* Start Time */}
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="datetime-start"
                label="Start Time"
                type="datetime-local"
                required
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={startTime.toISOString().slice(0, 19)?.toLocaleString()}
                onChange={(e) => handleSetDatetime(e)}
              />
            </FormControl>
          </Grid>
          {/* Category */}
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="category"
                label="Category"
                select
                required
                variant="outlined"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                {OPS_LOGS_OPERATION_CATEGORY_ENUM.map((opsCategory) => (
                  <MenuItem key={opsCategory.name} value={opsCategory.value}>
                    {opsCategory.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          {/* Sub Category */}
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="subCategory"
                label="Sub Category"
                select
                variant="outlined"
                value={subCategory}
                disabled={!category || !OPS_LOGS_SUB_CATEGORIES[category]}
                onChange={(e) => {
                  setSubCategory(e.target.value);
                }}
              >
                {category ? (
                  OPS_LOGS_SUB_CATEGORIES[category]
                    ?.sort((a, b) => a.value.localeCompare(b.value))
                    ?.map((subcategory) => (
                      <MenuItem key={subcategory.name} value={subcategory.value}>
                        {subcategory.name}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem value="" disabled>
                    No options available
                  </MenuItem>
                )}
              </TextField>
            </FormControl>
          </Grid>
          {/* Department */}
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="department"
                label="Department"
                select
                required
                variant="outlined"
                value={department}
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
              >
                {OPS_LOGS_DEPARTMENT_ENUM.map((dept) => (
                  <MenuItem key={dept.name} value={dept.value}>
                    {dept.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          {/* Additional Notes */}
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="additionalNotes"
                label="Additional Notes"
                variant="outlined"
                multiline
                rows={3}
                value={additionalNotes}
                onChange={(e) => setAdditionalNotes(e.target.value)}
              />
            </FormControl>
          </Grid>
          {/* Buttons */}
          <Grid item xs={12}>
            <Box className={classes.buttonContainer}>
              <Button variant="contained" color="primary" onClick={handleSubmitForm} disabled={!startTime || !category || !department}>
                Submit
              </Button>
              <Button variant="contained" color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

export default OperationLogsForm;
