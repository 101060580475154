import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDateTime, formatStringForReadbility } from '../../utils/ui.utils';

/**
 * Custom styles for the table, modal, and buttons.
 */
const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: '15px',
    backgroundColor: '#fff',
    // Styling for table header cells
    '& .p-datatable-thead > tr > th': {
      backgroundColor: '#f5f5f5',
      color: '#333',
      padding: '12px',
      borderBottom: '2px solid #ccc',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    // Styling for table body cells
    '& .p-datatable-tbody > tr > td': {
      padding: '12px',
      borderBottom: '1px solid #eee',
      textAlign: 'center'
    },
    // Zebra striping for even rows
    '& .p-datatable-tbody > tr:nth-child(even)': {
      backgroundColor: '#f9f9f9'
    },
    // Zebra striping for odd rows
    '& .p-datatable-tbody > tr:nth-child(odd)': {
      backgroundColor: '#fff'
    },
    // Styling for the paginator container
    '& .p-datatable-paginator': {
      borderTop: '1px solid #ccc',
      backgroundColor: '#f5f5f5',
      padding: '8px',
      borderRadius: '0 0 1px 1px',
      textAlign: 'center'
    },
    // Styling for buttons within the table
    '& .p-button': {
      margin: '0 4px',
      color: '#333'
    },
    // Styling for paginator pages container
    '& .p-datatable .p-paginator .p-paginator-pages': {
      marginRight: '10px'
    },
    // Styling for individual paginator pages
    '& .p-datatable .p-paginator .p-paginator-page': {
      color: '#333',
      fontSize: '14px'
    },
    // Styling for highlighted paginator page
    '& .p-datatable .p-paginator .p-paginator-page.p-highlight': {
      backgroundColor: '#007bff',
      color: '#fff'
    },
    // Styling for paginator page hover effect
    '& .p-datatable .p-paginator .p-paginator-page:hover': {
      backgroundColor: '#ddd'
    }
  },
  // Modal specific styling
  modal: {
    width: '50%',
    maxHeight: '80vh',
    overflowY: 'auto'
  },
  // Button styling using theme spacing
  button: {
    marginTop: theme.spacing(2)
  }
}));

/**
 * OperationLogsTable - Table component for operation logs.
 *
 * This component is responsible for rendering operations logs data in a tabular format.
 * It also handles displaying a pop-up dialog for robot state and ops logs notes.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.data - Operation logs data to be displayed in the table.
 * @return {JSX.Element} React table component with operation logs and a modal dialog.
 */
const OperationLogsTable = observer(({ data }) => {
  const classes = useStyles();
  const [showDataModal, setShowDataModal] = useState(false);
  const [modalData, setModalData] = useState('');
  const [robotStateData, setRobotStateData] = useState({});
  const [notesData, setNotesData] = useState('');

  // If there is no data provided, render a message indicating no data is available.
  if (!data || data.length === 0) {
    return (
      <Box>
        <Typography variant="h3">No Data Available</Typography>
      </Box>
    );
  }

  // Configuration for table columns. Each object defines the field, header name, and sortability.
  const tableColumns = [
    { field: 'serial_number', headerName: 'Serial Number', sortable: true },
    { field: 'operator', headerName: 'Operator', sortable: true },
    { field: 'downtime_start', headerName: 'Start Time', sortable: true },
    { field: 'downtime_end', headerName: 'End Time', sortable: false },
    { field: 'category', headerName: 'Category', sortable: false },
    { field: 'sub_category', headerName: 'Sub Category', sortable: false },
    { field: 'department', headerName: 'Department', sortable: true },
    { field: 'note', headerName: 'Notes', sortable: false },
    { field: 'robotState', headerName: 'Robot State', sortable: false }
  ];

  /**
   * Opens the modal dialog with the provided content.
   *
   * @param {string} content - The content to be displayed in the modal.
   */
  const handleModalOpen = (content) => {
    setModalData(content);
    setShowDataModal(true);
  };

  /**
   * Closes the modal dialog and resets its content.
   */
  const handleModalClose = () => {
    setShowDataModal(false);
    setModalData('');
  };

  /**
   * Formats an ISO date string into a more readable format.
   *
   * @param {string} date - The ISO date string.
   * @return {string} The formatted date string, or an empty string if no date is provided.
   */
  const formatISOdDate = (date) => (date ? formatDateTime(date) : '');

  /**
   * Renders either a button for viewing details or a formatted string based on the field type.
   *
   * For fields 'note' and 'robotState', it returns a button that opens a modal dialog (button is disabled).
   * For other fields, it returns a formatted string using a utility function.
   *
   * @param {string} field - The field name from the row data.
   * @param {Object} rowData - The row data object.
   * @return {JSX.Element|string} A button component or a formatted string.
   */
  const renderDataOrButton = (field, rowData) => {
    if (field === 'note' || field === 'robotState') {
      return (
        <Button variant="outlined" size="small" onClick={() => handleModalOpen(rowData[field])} disabled>
          View Details
        </Button>
      );
    }
    return formatStringForReadbility(rowData[field]);
  };

  /**
   * Renders a table column based on the provided column configuration.
   *
   * If the column represents a date field ('downtime_start' or 'downtime_end'), it formats the date.
   * Otherwise, it renders the cell content using renderDataOrButton.
   *
   * @param {Object} col - The column configuration object containing `field` and `headerName`.
   * @return {JSX.Element} The rendered Column component.
   */
  const renderColumn = (col) => {
    const { field, headerName } = col;
    if (field === 'downtime_start' || field === 'downtime_end') {
      return <Column key={field} field={field} header={headerName} body={(rowData) => formatISOdDate(rowData[field])} sortable />;
    }
    return (
      <Column
        key={field}
        field={field}
        header={headerName}
        body={(rowData) => renderDataOrButton(field, rowData)}
        sortable={col.sortable}
      />
    );
  };

  return (
    <>
      <DataTable
        value={data}
        className={classes.table}
        tableStyle={{ minWidth: '60rem' }}
        paginator
        rows={10}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        {tableColumns.map((col) => renderColumn(col))}
      </DataTable>

      <Dialog open={showDataModal} onClose={handleModalClose} maxWidth="md" fullWidth>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <p>{modalData}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default OperationLogsTable;
